<template>
  <div>
    <h2>Dashboard</h2>
    <RealtimeLocation />
    <p>Welcome to the Dashboard!</p>
    <button @click="logout">Logout</button>
    <div v-if="userInfo">
      <h3>User Information</h3>
<!--      <p>Name: {{ userInfo.name }}</p>-->
      <p>Email: {{ userInfo.email }}</p>
      <p>Last Login: {{ userInfo.last_login_at }}</p>
    </div>
  </div>
</template>

<script>
import RealtimeLocation from '@/components/RealtimeLocation.vue';
import axios from 'axios';

export default {
  components: {
    RealtimeLocation // Register the RealtimeLocation component
  },
  data() {
    return {
      userInfo: null
    };
  },
  mounted() {
    if (!localStorage.getItem('token')) {
      this.$router.push('/login');
    } else {
      // Token is available, fetch user info
      this.getUserInfo();
    }
  },
  methods: {
    logout() {
      // Clear token from localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('userEmail');
      // Redirect to the login page
      this.$router.push('/login');
    },
    getUserInfo() {
      const token = localStorage.getItem('token');
      axios.get('https://staff.msiserviceltd.com/api/user', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
          .then(response => {
            // Store user information

            this.userInfo = response.data;
            console.log(this.userInfo.email)
            localStorage.setItem("userEmail", this.userInfo.email);



          })
          .catch(error => {
            console.error('Error fetching user information:', error);
          });
    }
  }
};
</script>
