<template>
  <div class="home">
    <router-link to="/login">Login</router-link> <br>
    <router-link to="/dashboard">Dash</router-link>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {}
}
</script>

