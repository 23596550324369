<template>
  <div>
    <h1>Real-time Location</h1>
    <p v-if="error">{{ error }}</p>
    <p v-else-if="!latitude || !longitude">Loading...</p>
    <p v-else>
      Your current location is: ({{ latitude }}, {{ longitude }})<br>
      Accuracy: {{ accuracy }} meters<br>
      Heading: {{ heading }} degrees<br>
      Speed: {{ speed }} m/s<br>
      Altitude: {{ altitude }} meters<br>
      Altitude Accuracy: {{ altitudeAccuracy }} meters
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      latitude: null,
      longitude: null,
      accuracy: null,
      heading: null,
      speed: null,
      altitude: null,
      altitudeAccuracy: null,
      error: null,
    };
  },
  mounted() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
          this.successCallback,
          this.errorCallback,
          { enableHighAccuracy: true }
      );
    } else {
      this.error = 'Geolocation is not supported by this browser.';
    }
  },
  methods: {
    successCallback(position) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      this.accuracy = position.coords.accuracy;
      this.heading = position.coords.heading;
      this.speed = position.coords.speed;
      this.altitude = position.coords.altitude;
      this.altitudeAccuracy = position.coords.altitudeAccuracy;

      // Getting email from local storage
      const email = localStorage.getItem("userEmail");

      // WebSocket communication
      const ws = new WebSocket('wss://locationsocket.msiserviceltd.com');
      ws.onopen = () => {
        ws.send(JSON.stringify({
          latitude: this.latitude,
          longitude: this.longitude,
          accuracy: this.accuracy,
          heading: this.heading,
          speed: this.speed,
          altitude: this.altitude,
          altitudeAccuracy: this.altitudeAccuracy,
          email: email // Include the email data
        }));
      };
    },
    errorCallback(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          this.error = 'User denied the request for Geolocation.';
          break;
        case error.POSITION_UNAVAILABLE:
          this.error = 'Location information is unavailable.';
          break;
        case error.TIMEOUT:
          this.error = 'The request to get user location timed out.';
          break;
        case error.UNKNOWN_ERROR:
          this.error = 'An unknown error occurred.';
          break;
      }
    }
  }
};
</script>

<style scoped>
/* Add your component styles here */
</style>
