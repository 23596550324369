<template>
  <div>
    <h2>Login</h2>
    <form @submit.prevent="login">
      <input type="email" v-model="email" placeholder="Email" required :disabled="otpRequested">
      <button type="button" @click="requestOTP" :disabled="otpRequested">Request OTP</button> <br>
      <input type="tel" v-model="otp" placeholder="OTP" required @input="validateOTP">
      <button type="submit" :disabled="!otpValid">Login</button>
    </form>
    <p v-if="showBrowserWarning" style="color: red;">
      This app may not work properly if the browser is not Chrome/121.0.0.0 Safari/537.36
    </p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      otp: '',
      otpRequested: false,
      otpValid: false,
      showBrowserWarning: false,
    };
  },
  mounted() {
    // Check user agent for browser warning
    this.checkBrowser();
  },
  methods: {
    async requestOTP() {
      try {
        await axios.post('https://staff.msiserviceltd.com/api/login', {
          email: this.email
        });

        this.otpRequested = true;

        setTimeout(() => {
          this.otpRequested = false;
        }, 30000);
      } catch (error) {
        console.error('Error requesting OTP:', error);
      }
    },
    validateOTP() {
      this.otpValid = this.otp.length === 6;
    },
    async login() {
      try {
        const response = await axios.post('https://staff.msiserviceltd.com/api/verify', {
          email: this.email,
          otp: this.otp
        });

        localStorage.setItem('token', response.data.token);

        this.$router.push('/dashboard');
      } catch (error) {
        console.error('Login error:', error);
      }
    },
    checkBrowser() {
      const userAgent = navigator.userAgent;
      if (!/Chrome\/121\.0\.0\.0 Safari\/537\.36/.test(userAgent)) {
        this.showBrowserWarning = true;
      }
    }
  }
};
</script>
